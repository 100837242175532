.App {
  text-align: center;
  overflow: hidden;
}

body {
  background-color: #f5f5f5;
  font-family: 'PT Sans', sans-serif;
}

