@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@700;900&display=swap');


body {
  margin: 0;
  font-family: 'PT Sans', sans-serif !important;
  
}

#buttonSearch {
  font-family: 'PT Sans', sans-serif !important;
}

b, p, h1, h2, h3, h4, h5, h6, span, div, button, input, label, small {
  font-family: 'PT Sans', sans-serif !important;

}

.tabulka {
  margin-top: -20px
}

.tabulka  p, span{

  font-size: 0.8em !important;

 }


td {
  color: #606060 !important;
  font-size: 1rem;
}

/* Change the active border color of the DateRangePicker when focused */
.my-date-range-picker .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #929090 !important;
}

/* Change the active label color of the DateRangePicker when focused */
.my-date-range-picker .Mui-focused .MuiInputLabel-outlined  {
  color: #929090 !important;
}


/* Change the active border color of the Autocomplete when focused */
.mui-autocomplete-focused.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #929090 !important;
}

/* Change the active label color of the Autocomplete when focused */
.mui-autocomplete-focused.Mui-focused {
  color: #57719f !important;
}


#cenaKarta {
  position: relative;
  margin-top: 250px;
  margin-bottom: 20px;
  margin-right: 20px;
}


.phone {
  color: rgb(1, 67, 97);
  vertical-align: 'middle';
  margin-left: 10px;
}

.deposit {
  font-weight: 400 !important;
  color: #a5a19f;
  position: absolute;
  margin-left: -40px;
  margin-top: -25px !important;
}



.span-color {
  color: #57719f;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {

  margin: auto;
  width: 50%;
  padding: 10px;
  margin-top: 40px;
  padding-bottom: 50px;


 }

 .MuiInputBase-root {
  margin-bottom: 20px !important;

 }





 @media (max-width: 1281px) {

  #cSlider {
    width: 25vw !important;
   }
  
  .MuiTypography-h6 {
    font-size: 0.9rem !important;
  
  }
  

 
  h1 {
    font-size: 2rem !important;
  }
  
 

  .MuiCardActions-root {
    font-size: 10% !important;

  }

  #mPicker {
    width: 90vw !important;
  
  }

  #buttonSearch {
    width: 90vw !important;
    margin-top: 10px !important;
   

  }

  


 
 

}

@media (max-width: 899px) {
  
  #cSlider {
    width: 80vw !important;
   }


 }


 @media (max-width: 600px) {
  

   td {
 
    font-size: 0.8rem;
  }

 }
